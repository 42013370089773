import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"
import styled from "styled-components"
import { Link } from "gatsby"

import { H1, H2, P } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"

import imageMagasin from "../images/magasin.jpg"
import imageTraiteur from "../images/traiteur.jpg"
import imageVins from "../images/vins.jpg"
import imagePanier from "../images/panier.jpg"

import Header from "../components/header"
import Hero from "../components/hero"

const Button = styled.a`
  margin: 20px 0;
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 4px;
`

const Page = () => (
  <div>
    <SEO title="Raineri" />
    <Header/>

    <H1 className="text-center">Dispositions COVID-19</H1>

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <P>À notre aimable clientèle,</P>
          <P>Suite aux décisions prises par le Conseil national de sécurité et pour nous conformer au respect des règles de la distanciation sociale :</P>
          <P>À l’intérieur du magasin, il est demandé de se maintenir à une <strong>distance d'1,5m</strong> avec les autres personnes.</P>
          <P>Par mesure d’hygiène, le payement électronique est recommandé.</P>
          <P>Un tout grand merci pour votre compréhension</P>
          <P className="mt-5 text-right font-weight-bold">L’équipe Raineri Gembloux</P>
        </div>
      </div>
    </Section>
  </div>
)

export default Page
