import React from "react"
import "../bootstrap.min.css"
import styled from "styled-components"

import { H2, P } from "./text"
import Section from "./section"

const Container = styled.div`
  padding: 100px;
  color: #ffffff;
  background-color: rgb(60, 52, 47);

  p {
    color: #ffffff;
  }

  @media (max-width: 992px) {
    padding: 20px;
  }
`

const Footer = () =>
  <Container>
    <Section className="container" style={{marginBottom: 0}}>
      <div className="row">
        <div className="col-md-6">
          <H2>Notre magasin</H2>
          <P>
            Rue de la sucrerie 4A <br/>
            5030 Gembloux <br/>
            Tel: 081 61 46 10
          </P>
          <P>TVA BE828 987 536</P>
          <P>gembloux@raineri.be</P>
        </div>
        <div className="col-md-6">
          <H2>Horaire</H2>
          {/* <div style={{backgroundColor: "rgb(204, 29, 29)", marginBottom: "20px", color: "white", padding: "10px", fontSize: "20px"}}>
            Nous serons exceptionnellement ouvert le lundi 21 décembre et lundi 28 décembre de 9 à 18h.
            <br/><br/>
            <strong>La magasin sera fermé le samedi 2 janvier.</strong>
          </div> */}
          <P>Mardi à jeudi de 9h à 18h</P>
          <P>Vendredi de 9h à 18h30</P>
          <P>Samedi de 9h à 18h</P>
          <P style={{fontStyle: "italic"}}>Fermé le lundi et dimanche</P>
        </div>
      </div>
    </Section>
  </Container>

export default Footer
