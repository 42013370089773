import React from "react"
import "../bootstrap.min.css"
import styled from "styled-components"

export const H1 = styled.h1`
  padding: 60px 0;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
`

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
  color: #333;
`

export const P = styled.p`
  color: #666;
  font-size: 20px;
`

export const PSmall = styled.p`
  color: #555;
  font-size: 17px;
`