import React from "react"
import styled from "styled-components"

import logo from "../images/logo-raineri.png"

const Shadow = styled.div`
  padding-top: 150px;
  padding-left: 200px;
  padding-right: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  text-align: center;

  @media (max-width: 992px) {
    padding: 150px 0 0 0;
  }
`

const InnerWrapper = styled.div`
  width: 70%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 90%;
  }
`

const Title = styled.p`
  color: white;
  font-size: 82px;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 44px;
  }
`

const Subtitle = styled.p`
  color: white;
  font-size: 22px;
  font-weight: bold;

  @media (max-width: 992px) {
    font-size: 20px;
  }
`

const Announcement = styled.p`
  background-color: #B31833;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 20px;
`

const Button = styled.a`
  margin: 20px 0;
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 4px;

  &:hover {
    text-decoration: none;
    background-color: white;
    color: black;
  }
`

const Hero = ({ title, subtitle, image, menuFetes = false }) =>
  <div style={{height: '700px', backgroundSize: 'cover', backgroundImage: `url(${image})`, backgroundPosition: 'center', position: 'relative'}}>
    <Shadow>
      <InnerWrapper>
        <img src={logo} height="50px" />
        <Title>{ title }</Title>
        <Subtitle>{ subtitle }</Subtitle>

        {
          !menuFetes
          ? <></>
          : <div className="text-center mt-5">
            <Button href="/fetes">VOIR LE MENU DES FÊTES 🎄</Button>
          </div>
        }

        {/* <Announcement>
          Dégustation de vins le 28 mars de 10 à 17h.
        </Announcement> */}
      </InnerWrapper>
    </Shadow>
  </div>

export default Hero
